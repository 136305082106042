import { template as template_849a4da48fb04ac9ade30668e01527d6 } from "@ember/template-compiler";
const FKLabel = template_849a4da48fb04ac9ade30668e01527d6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
