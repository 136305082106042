import { template as template_1486a22223644588b6e2f1a3dc51b9f6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1486a22223644588b6e2f1a3dc51b9f6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
