import { template as template_028b6d638df64bd2bb43a6c5da8b38b7 } from "@ember/template-compiler";
const SidebarSectionMessage = template_028b6d638df64bd2bb43a6c5da8b38b7(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
